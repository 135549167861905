<template>
    <div class="level-card__footer" v-if="blockchain">
        <div class="level-card__footer-left">
            <div class="level-card__footer-item level-card__partners">
                <div class="level-card__footer-top level-card__partners-top">
                    {{ $t("matrix.stats.partners") }}
                </div>
                <div class="level-card__partners-bottom level-svg">
                    <svg-vue icon="people" />
                    {{ blockchain.totalPartners }}
                </div>
            </div>
            <div class="level-card__footer-item level-card__cycles">
                <div class="level-card__footer-top level-card__cycles-top">
                    {{ $t("matrix.stats.cycles") }}
                </div>
                <div class="level-card__cycles-bottom level-svg">
                    <svg-vue icon="refresh" />
                    {{ blockchain.totalSlots }}
                </div>
            </div>
        </div>
        <div class="level-card__footer-right">
            <div class="level-card__footer-item level-card__total">
                <div class="level-card__footer-top level-card__total-top">
                    {{ $t("matrix.stats.pureRevenue") }}
                </div>
                <div class="level-card__total-bottom level-svg">
                    <svg-vue icon="coin-vertical" />
                    {{ blockchain.pureRevenueCycle }} DAI
                </div>
            </div>
            <div class="level-card__footer-item level-card__total">
                <div class="level-card__footer-top level-card__total-top">
                    {{ $t("matrix.stats.revenue") }}
                </div>
                <div class="level-card__total-bottom level-svg">
                    <svg-vue icon="wallet-no-opacity" />
                    {{ blockchain.totalSpend }} DAI
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { blockchain: Object },
}
</script>
