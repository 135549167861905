<template>
    <div class="level-wrapper">
        <div class="level-nav">
            <router-link
                :to="{ name: 'classiclevel', params: { lvl: +this.lvl - 1 } }"
                class="level-arrow arrow-prev btn-reset"
                :class="[!prevLevelAvailable && 'hidden']"
            >
                <svg-vue icon="arrow-left" />
                {{ $t("matrix.lvlCapitalized") }} {{ lvl - 1 }}
            </router-link>
            <span class="level-card__lvl">{{ $t("matrix.level") }} {{ lvl }}</span>
            <router-link
                :to="{ name: 'classiclevel', params: { lvl: +this.lvl + 1 } }"
                class="level-arrow arrow-next btn-reset"
                :class="[!nextLevelAvailable && 'hidden']"
            >
                {{ $t("matrix.lvlCapitalized") }} {{ +lvl + 1 }}
                <svg-vue icon="arrow-right" />
            </router-link>
        </div>
        <div class="level-inner">
            <div class="level-card level-card--classic mb-5">
                <div class="level-card__inner" v-if="!loading">
                    <header class="level-card__header">
                        <div class="level-card__title">
                            <span class="level-card__force-id">Force ID</span>
                            <span class="level-card__name">{{ user.name }}</span>
                        </div>
                        <div class="level-card__count d-flex align-center">
                            <svg-vue icon="symbol.dai-white" />
                            {{ price }}
                        </div>
                    </header>

                    <div class="level-card__tree d-flex justify-center">
                        <div id="svg-tooltip" />

                        <RenderSvg :master="user" :cardType="cardType" :blockchain="blockchain" />
                    </div>

                    <LevelStats :blockchain="blockchain" />
                </div>

                <UiLoader v-else theme="block" :loading="true" />
            </div>
        </div>
        <div class="level-widgets-wrapper">
            <div class="level-widgets-left" v-if="!loading && isMyAccount && blockchain">
                <div class="level-balance">
                    <UiLoader v-if="withdrawLoading" theme="block" :loading="true" />
                    <template v-else>
                        <div class="level-balance__value">
                            <span class="level-balance__value-top">{{ $t("matrix.frozenBalance") }}</span>
                            <span class="level-balance__value-bottom">{{ blockchain.totalFrozen }} DAI</span>
                        </div>
                    </template>
                </div>
                <div class="level-balance__buttons">
                    <UiButton
                        type="secondary"
                        v-if="blockchain.totalFrozen !== '0'"
                        class="level-balance__button level-balance__withdraw"
                        :text="withdrawText"
                        @click="handleWithdrawClick"
                    />
                </div>
            </div>
            <div class="level-widgets-right" v-if="blockchain && isMyAccount">
                <div class="level-widgets">
                    <div class="level-widgets__item level-activation" :class="[recyclePending && 'pending']">
                        <label class="level-widgets__switch switch" @click.prevent.stop="handleRecycleChange">
                            <input class="switch__input" type="checkbox" :class="[autoRecycleValue && 'checked']" />
                            <span class="switch__slider switch__slider--round"></span>
                        </label>
                        <div class="level-widgets-checked">
                            <span class="level-widgets__desc">{{ $t("matrix.reActivation") }}</span>
                        </div>
                        <div class="level-widgets-unchecked">
                            <span class="level-widgets__desc level-widgets__auto">{{ $t("matrix.reCycle") }}</span>
                            <button class="level-widgets__button btn-reset" type="button">
                                {{ $t("matrix.activation") }}
                            </button>
                            <div class="level-widgets__tooltip"><svg-vue icon="info" /></div>
                        </div>
                    </div>
                    <div
                        class="level-widgets__item level-upgrade"
                        :class="[autoupgradePending && 'pending', !nextLevelAvailable && level !== 11 && 'available']"
                    >
                        <label class="level-widgets__switch switch" @click.prevent.stop="handleUpgradeChange">
                            <input class="switch__input" :class="[autoUpgradeValue && 'checked']" type="checkbox" />
                            <span class="switch__slider switch__slider--round"></span>
                        </label>
                        <div class="level-widgets-checked">
                            <span class="level-widgets__desc">{{ $t("matrix.autoUpgrade") }}</span>
                        </div>
                        <div class="level-widgets-unchecked">
                            <span class="level-widgets__desc level-widgets__auto">{{ $t("matrix.autoUpgrade") }}</span>
                            <span class="level-widgets__turn">{{ $t("matrix.autoUpgradeError") }}</span>
                        </div>
                    </div>
                </div>
                <UiButton
                    v-if="this.levelInfo.active === 'gap'"
                    class="level-balance__button level-balance__active"
                    :text="$t('matrix.activateLevel')"
                    @click="handleActivationClick"
                />
            </div>
        </div>
        <UiPagination :meta="paginationMeta" @onChange="handlePaginationSelect" />

        <div class="page-loader" :class="[withdrawProcessing && 'is-active']">
            <UiLoader :loading="true" />
            <div class="page-loader__message">{{ $t("pleaseWait") }}, <br />{{ $t("operationInProgress") }}</div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import { getClassicTypeByLevel, getClassicPriceByLevel } from "@/helpers/matrix"
import { isSameAccount } from "@/helpers/user"
import { Log } from "@/helpers/dev"
import RenderSvg from "@/components/Matrix/Render/ClassicSvg.vue"
import LevelStats from "@/components/Matrix/Level/Stats.vue"

export default {
    props: { lvl: [Number, String], user: Object },
    components: { RenderSvg, LevelStats },
    data() {
        return {
            activeSlot: 0,
            blockchain: null,
            loading: true,
            withdrawLoading: false,
            withdrawProcessing: false,
            autoUpgradeInner: null,
            autoRecycleInner: null,
            recyclePending: false,
            autoupgradePending: false,
            levels: []
        }
    },
    async mounted() {
        const [err, levels] = await this.getProgramLevels(this.user.account)
        this.levels = levels

        await this.getStructure({ type: "initial" })
    },

    computed: {
        isMyAccount() {
            // return true
            return isSameAccount(this.getAccount, this.user.account)
        },
        level() {
            return Number(this.lvl) - 1
        },
        cardType() {
            return getClassicTypeByLevel(this.level)
        },
        price() {
            return getClassicPriceByLevel(this.level)
        },
        totalSlots() {
            if (!this.blockchain) return 1

            return Number(this.blockchain.totalSlots) + 1
        },
        prevLevelAvailable() {
            try {
                return this.levels[this.level - 1].active
            } catch {
                return false
            }
        },
        nextLevelAvailable() {
            try {
                return this.levels[this.level + 1].active
            } catch {
                return false
            }
        },
        levelInfo() {
            return this.levels[this.level]
        },
        paginationMeta() {
            return {
                total: this.totalSlots,
                current: this.activeSlot + 1
            }
        },
        autoRecycleValue() {
            return this.autoRecycleInner !== null ? this.autoRecycleInner : this.blockchain.autoRecycle
        },
        autoUpgradeValue() {
            return this.autoUpgradeInner !== null ? this.autoUpgradeInner : this.blockchain.autoUpgrade
        },
        isWithdrawFree() {
            return this.nextLevelAvailable || this.level === 11
        },
        withdrawText() {
            if (this.isWithdrawFree) {
                return this.$t("matrix.withdraw")
            } else {
                return this.$t("matrix.withdrawWithCommission")
            }
        },
        ...mapGetters("crypto", ["getAccount"])
    },
    methods: {
        async swalConstructor(text) {
            const res = await this.$swal
                .fire({
                    showCancelButton: true,
                    confirmButtonText: this.$t("confirm"),
                    cancelButtonText: this.$t("cancel"),
                    text: this.$t(text)
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        return true
                    }
                })

            return res
        },
        async processWithdraw() {
            const [err, res] = await this.withdrawFrozen({
                type: this.cardType,
                lvl: this.level.toString(),
                onBlockchainPending: () => {
                    this.withdrawProcessing = true
                }
            })

            if (err) {
                this.$swal(err.message)
            }
        },
        async handleWithdrawClick(e) {
            if (this.withdrawLoading) return
            this.withdrawLoading = true

            if (!this.isWithdrawFree) {
                const modalRes = await this.swalConstructor("matrix.activateCommisionText")

                if (modalRes) {
                    await this.processWithdraw()
                }
            } else {
                await this.processWithdraw()
            }

            // this.getStructure({ type: "refresh" })

            this.withdrawLoading = false
            this.withdrawProcessing = false
        },
        async handleRecycleChange(e) {
            let agreed = true

            if (!this.autoRecycleValue === false) {
                agreed = await this.swalConstructor("matrix.recycleCommisionText")
                if (!agreed) return
            }

            this.recyclePending = true

            const [err, res] = await this.changeAutoReCycle({
                flag: !this.autoRecycleValue,
                lvl: this.level.toString()
            })

            this.autoRecycleInner = err ? this.autoRecycleValue : !this.autoRecycleValue

            this.recyclePending = false
        },
        async handleUpgradeChange(e) {
            this.autoupgradePending = true

            const [err, res] = await this.changeAutoUpgrade({
                flag: !this.autoUpgradeValue,
                lvl: this.level.toString()
            })

            this.autoUpgradeInner = err ? this.autoUpgradeValue : !this.autoUpgradeValue

            this.autoupgradePending = false
        },
        async getStructure({ type, slot = 0 }) {
            this.loading = true

            const [err, res] = await this.requestStructure({
                slot,
                account: this.user.account,
                level: this.level,
                type: this.cardType,
                fetchUser: true,
                countRevenue: this.blockchain && this.blockchain.pureRevenue
            })

            Log(`structure responce for slot - ${slot}`, { res })
            this.blockchain = res ? { ...res } : null

            if (type === "initial" && +res.totalSlots >= 1) {
                await this.handlePaginationSelect(+res.totalSlots + 1)
            }

            this.loading = false
        },
        async handlePaginationSelect(slot) {
            this.loading = true

            this.activeSlot = slot - 1
            await this.getStructure({ type: "nav", slot: slot - 1 })

            this.loading = false
        },
        async handleActivationClick() {
            let agreed = true

            if (!this.autoRecycleValue) {
                agreed = await this.swalConstructor("matrix.recyclePurchaseText")

                if (!agreed) return

                await this.handleRecycleChange()
            }

            if (this.autoRecycleValue) {
                this.setActivationModal({ visible: true, type: this.cardType, level: this.level, cost: this.price })
            }
        },
        ...mapActions("crypto", [
            "requestStructure",
            "getProgramLevels",
            "changeAutoReCycle",
            "changeAutoUpgrade",
            "withdrawFrozen"
        ]),
        ...mapMutations("ui", ["setActivationModal"])
    },
    watch: {
        lvl(newLvl) {
            this.loading = true

            this.activeSlot = 0
            this.getStructure({ type: "initial" })

            this.autoUpgradeInner = null
            this.autoRecycleInner = null

            this.loading = false
        }
    }
}
</script>
