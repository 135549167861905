<template>
    <svg width="100%" height="100%" viewBox="0 0 635 173" fill="none">
        <defs>
            <pattern
                v-for="av in allAvatars"
                :key="av.account"
                :id="av.account"
                :width="av.render"
                :height="av.render"
                patternUnits="objectBoundingBox"
            >
                <image
                    :href="av.src"
                    :width="av.size"
                    :height="av.size"
                    :x="av.x || 0"
                    :y="av.y || 0"
                    preserveAspectRatio="xMidYMid slice"
                />
            </pattern>
        </defs>
        <g>
            <line
                x1="122.384"
                y1="69.4065"
                x2="294.406"
                y2="32.6156"
                stroke="white"
                stroke-linecap="round"
                stroke-dasharray="5 5"
            />
            <line
                x1="0.5"
                y1="-0.5"
                x2="176.412"
                y2="-0.5"
                transform="matrix(-0.977885 -0.209143 -0.209143 0.977885 519 70)"
                stroke="white"
                stroke-linecap="round"
                stroke-dasharray="5 5"
            />
            <template v-if="cardType === 's6'">
                <line
                    x1="27.2955"
                    y1="129.358"
                    x2="99.3576"
                    y2="102.704"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-dasharray="5 5"
                />
                <line
                    x1="0.5"
                    y1="-0.5"
                    x2="77.3332"
                    y2="-0.5"
                    transform="matrix(-0.937904 -0.346896 -0.346896 0.937904 221 130)"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-dasharray="5 5"
                />
                <line
                    x1="419.296"
                    y1="129.358"
                    x2="491.358"
                    y2="102.704"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-dasharray="5 5"
                />
                <line
                    x1="0.5"
                    y1="-0.5"
                    x2="77.3332"
                    y2="-0.5"
                    transform="matrix(-0.937904 -0.346896 -0.346896 0.937904 612 130)"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-dasharray="5 5"
                />
            </template>
        </g>

        <g
            :content="`${master.name} ${master.account}`"
            v-tippy="{ maxWidth: 380 }"
            @click="clickTooltip(`${master.name} ${master.account}`)"
        >
            <circle cx="321" cy="28" r="28" fill="white" />
            <circle cx="321" cy="28" r="26" :fill="getMasterFill()" />
        </g>

        <g
            :content="tooltipContent('lvl1', 0)"
            v-tippy="{ maxWidth: 380 }"
            @click="clickTooltip(getAddress('lvl1', 0))"
        >
            <circle cx="124" cy="94" r="24" fill="white" />
            <circle cx="124" cy="94" r="22" :fill="getFill('lvl1', 0)" />
        </g>

        <template v-if="cardType === 's3'">
            <line
                data-v-79f454a8=""
                x1="321.5"
                y1="55"
                x2="321.5"
                y2="75.5"
                stroke="white"
                stroke-linecap="round"
                stroke-dasharray="5 5"
            ></line>

            <g
                :content="tooltipContent('lvl1', 1)"
                v-tippy="{ maxWidth: 380 }"
                @click="clickTooltip(getAddress('lvl1', 1))"
            >
                <circle cx="321" cy="94" r="24" fill="white" />
                <circle cx="321" cy="94" r="22" :fill="getFill('lvl1', 1)" />
            </g>
        </template>

        <g
            :content="tooltipContent('lvl1', cardType === 's6' ? 1 : 2)"
            v-tippy="{ maxWidth: 380 }"
            @click="clickTooltip(getAddress('lvl1', cardType === 's6' ? 1 : 2))"
        >
            <circle cx="516" cy="94" r="24" fill="white" />
            <circle cx="516" cy="94" r="22" :fill="getFill('lvl1', cardType === 's6' ? 1 : 2)" />
        </g>

        <template v-if="cardType === 's6'">
            <g
                :content="tooltipContent('lvl2', 0)"
                v-tippy="{ maxWidth: 380 }"
                @click="clickTooltip(getAddress('lvl2', 0))"
            >
                <circle cx="21.5" cy="151.5" r="21.5" fill="white" />
                <circle cx="21.5" cy="151.5" r="19.5" :fill="getFill('lvl2', 0)" />
            </g>
            <g
                :content="tooltipContent('lvl2', 1)"
                v-tippy="{ maxWidth: 380 }"
                @click="clickTooltip(getAddress('lvl2', 1))"
            >
                <circle cx="221.5" cy="151.5" r="21.5" fill="white" />
                <circle cx="221.5" cy="151.5" r="19.5" :fill="getFill('lvl2', 1)" />
            </g>
            <g
                :content="tooltipContent('lvl2', 2)"
                v-tippy="{ maxWidth: 380 }"
                @click="clickTooltip(getAddress('lvl2', 2))"
            >
                <circle cx="418.5" cy="151.5" r="21.5" fill="white" />
                <circle cx="418.5" cy="151.5" r="19.5" :fill="getFill('lvl2', 2)" />
            </g>
            <g
                :content="tooltipContent('lvl2', 3)"
                v-tippy="{ maxWidth: 380 }"
                @click="clickTooltip(getAddress('lvl2', 3))"
            >
                <circle cx="613.5" cy="151.5" r="21.5" fill="white" />
                <circle cx="613.5" cy="151.5" r="19.5" :fill="getFill('lvl2', 3)" />
            </g>
        </template>
    </svg>
</template>

<script>
import { copyToClipboard } from "@/helpers/clipboard"
import { createImageHref } from "@/helpers/images"

export default {
    props: {
        master: Object,
        cardType: String,
        blockchain: Object,
        tooltip: Object,
    },
    computed: {
        allAvatars() {
            let renderQuality = window.devicePixelRatio || 1
            renderQuality = renderQuality * 1.5

            const mapperFunc = (u, type) => {
                let size = 60
                let render = 28

                switch (type) {
                    case "master":
                        size = 52
                        render = 28 / renderQuality
                        break
                    case "lvl1":
                        size = 46
                        render = 22 / renderQuality
                        break
                    case "lvl2":
                        size = 39
                        render = 19.5 / renderQuality
                        break
                    default:
                        break
                }
                return {
                    size,
                    render,
                    account: u.account,
                    src: createImageHref(u.avatar),
                }
            }

            const images = [
                ...[this.master].map((u) => mapperFunc(u, "master")),
                ...this.blockchain.lvl1.filter((x) => x).map((u) => mapperFunc(u, "lvl1")),
                ...this.blockchain.lvl2.filter((x) => x).map((u) => mapperFunc(u, "lvl2")),
                ...this.blockchain.lvl3.filter((x) => x).map((u) => mapperFunc(u, "lvl3")),
            ]
            return images
        },
    },
    methods: {
        tooltipContent(lvl, index) {
            return this.getAddress(lvl, index) || this.$t("matrix.tooltip_empty_state")
        },
        isActive(lvl, index) {
            return this.getAddress(lvl, index) === null
        },
        getAddress(lvl, index, isPure) {
            if (!this.blockchain || !this.blockchain[lvl]) return null

            const target = this.blockchain[lvl][index]

            if (target && typeof target === "object") {
                return isPure ? target : `${target.name} ${target.account}`
            } else if (typeof target === "string") {
                return target
            }

            return null
        },
        getFill(lvl, index) {
            const acc = this.getAddress(lvl, index, true)

            return acc ? `url('#${acc.account}')` : "#C4C4C4"
            // return acc ? `#38C948` : "#C4C4C4"
        },
        getMasterFill() {
            return `url('#${this.master.account}')`
            // return "#38C948"
        },
        clickTooltip(text) {
            if (!text) return
            let address = text.split(" ")[1] || text
            copyToClipboard(address)
                .then(() => {
                    this.$toast.success(`${address} ${this.$t("matrix.copied")}`)
                })
                .catch(() => {
                    this.$toast.error(`${this.$t("matrix.copyError")}`)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
g {
    &:focus {
        outline: none;
    }
}
</style>
