<template>
    <section class="level section-offset">
        <header class="level-header">
            <h2 class="level__title section-title">Classic</h2>
            <router-link :to="{ path: '/systems/classic' }" class="level-back">
                <svg-vue icon="arrow-back" />
                {{ $t("matrix.back") }}
            </router-link>
        </header>
        <template v-if="user">
            <MatrixLevelClassic :lvl="lvl" :user="user" />
        </template>
        <div v-else class="my-5">
            <UiLoader theme="block" :loading="true" />
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import MatrixLevelClassic from "@/components/Matrix/LevelClassic.vue"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"

export default {
    props: ["lvl"],
    components: { MatrixLevelClassic },
    mixins: [pageReadyByConnect],
    data() {
        return {
            user: null
        }
    },
    async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    computed: {
        ...mapGetters("crypto", ["getAccount", "isConnected"])
    },

    methods: {
        async pageReady() {
            const [regErr, regParent] = await this.checkRegistration(true)
            if (regErr) {
                this.$swal(this.$t("errors.registrationError"))
                await this.$router.push({ name: "signup" })
                return
            }

            const accountQuery = this.$route.query.account
            const targetAccount = accountQuery || this.getAccount

            try {
                let userResponse = await this.guessUser(targetAccount)
                if (!userResponse) throw new Error()

                const [levelsErr, levels] = await this.getProgramLevels(userResponse.account)

                try {
                    const activeLevel = levels.findLast((x) => x.active).lvl

                    if (Number(this.lvl) - 1 > activeLevel) throw new Error()

                    if (!Number.isFinite(Number(this.lvl))) {
                        await this.$router.push({ name: "classiclevel", params: { lvl: activeLevel + 1 } })
                    }
                } catch (err) {
                    // this.$swal(this.$t("errors.levelNotValid"))
                    // this.$router.push({ name: "dashboard" })
                }

                this.user = userResponse
            } catch (err) {
                this.$swal(this.$t("errors.userNotFound"))
                await this.$router.push({ name: "dashboard" })
            }
        },
        ...mapActions("crypto", ["checkRegistration", "getProgramLevels"]),
        ...mapActions("user", ["guessUser"])
    }
}
</script>
